import * as React from "react"
import { Box, Center, Spinner } from "@chakra-ui/react"
import { useRouter } from "next/router"

import { Role } from "lib/graphql"
import { useMe } from "lib/hooks/useMe"

import { Limiter } from "./Limiter"
import { Nav } from "./Nav"

interface Props {
  children: React.ReactNode
}

export function HomeLayout(props: Props) {
  const { me, loading } = useMe()
  const router = useRouter()

  React.useEffect(() => {
    if (loading) return
    if (!me) {
      router.replace("/login")
    } else if (me.role === Role.Admin) {
      router.replace(`/admin/entities`)
    }
  }, [loading, me, router])

  if (loading || !me || me.role === Role.Admin) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box minH="100vh" pos="relative">
      <Nav />
      <Limiter pt="80px">{props.children}</Limiter>
    </Box>
  )
}
