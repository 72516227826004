import * as React from "react"
import { FiLogOut, FiMenu, FiMoon, FiSettings, FiSun, FiUploadCloud, FiUser } from "react-icons/fi"
import type { LinkProps } from "@chakra-ui/react"
import { Image, Text } from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
import {
  Avatar,
  Box,
  Fade,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react"
import NextLink from "next/link"
import { useRouter } from "next/router"

import type { EntityOverviewFragment } from "lib/graphql"
import { useGetReportQuery } from "lib/graphql"
import { Role, useGetEntityQuery } from "lib/graphql"
import { useLogout } from "lib/hooks/useLogout"
import { useMe } from "lib/hooks/useMe"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { REPORT_TYPES } from "lib/static/reportType"

import { CreateExpenseForm } from "./CreateExpenseForm"
import { CreateInvoiceForm } from "./CreateInvoiceForm"
import { Limiter } from "./Limiter"
import { LinkButton } from "./LinkButton"
import { Modal } from "./Modal"
import { TasksPopover } from "./TasksPopover"
import { UpdatesPopover } from "./UpdatesPopover"

export function Nav() {
  const { me, loading } = useMe()

  const bt = useBetterTranslation()
  const router = useRouter()
  const entityId = router.query.id as string | undefined
  const year = router.query.year as string | undefined
  const quarter = router.query.quarter as string | undefined
  const incomeDataId = router.query.incomeId as string | undefined
  const reportId = router.query.reportId as string | undefined
  const createInvoiceProps = useDisclosure()
  const createExpenseProps = useDisclosure()
  const logout = useLogout()
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === "dark"

  const { data } = useGetEntityQuery({
    skip: !!!entityId,
    variables: { where: { id: { equals: entityId } } },
  })
  const entity = data?.entity

  const [isMobile] = useMediaQuery("(max-width: 500px)")

  return (
    <Box
      w="100%"
      pos="fixed"
      top={0}
      left={0}
      borderBottom="1px solid"
      borderColor={useColorModeValue("gray.100", "gray.700")}
      zIndex={500}
    >
      <Limiter
        display="flex"
        transition="200ms all"
        py={{ base: 4, md: 3 }}
        bg={useColorModeValue("white", "gray.800")}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        {/* Left link list */}
        <HStack spacing={6} color="gray.400" fontSize="sm">
          <HomeLink
            href="/"
            color={useColorModeValue("pink.600", "pink.400")}
            pl={0}
            textTransform="uppercase"
            fontWeight="bold"
          >
            <Image
              alt="greymen logo"
              src={useColorModeValue("/logo.png", "/logo-white.png")}
              h="40px"
              alignSelf={{ base: "flex-start", md: "flex-end" }}
            />
          </HomeLink>

          {entity && router.pathname.includes("entities") && (
            <BreadcrumbLinks
              year={year}
              quarter={quarter ? quarter : undefined}
              entity={entity}
              incomeDataId={incomeDataId}
              reportId={reportId}
            />
          )}
        </HStack>

        {/* Right link list */}

        {!me && !loading && (
          <Fade in>
            <HStack spacing={4} display={{ base: "none", md: "flex" }}>
              <LinkButton href="/login" variant="ghost">
                {bt({ en: "Login", nl: "Login" })}
              </LinkButton>
            </HStack>
          </Fade>
        )}

        {/* Updates Popover */}
        {!isMobile && <UpdatesPopover />}

        {/* Tasks Popover */}
        {!isMobile && me && <TasksPopover userId={me.id} />}

        {/* Upload menu list */}
        {!isMobile && me && me.companyEntities.length > 0 && (
          <Box mr={2}>
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                display={{ base: "flex", md: me ? "flex" : "none" }}
                variant="ghost"
                borderRadius="full"
                icon={<Box size="22" as={FiUploadCloud} />}
              />
              <MenuList fontSize="md">
                <MenuItem closeOnSelect={false} onClick={createExpenseProps.onOpen}>
                  {bt({ en: "Upload expense", nl: "Uitgave uploaden" })}
                </MenuItem>
                <MenuItem closeOnSelect={false} onClick={createInvoiceProps.onOpen}>
                  {bt({ en: "Upload invoice", nl: "Omzetfactuur uploaden" })}
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}

        {/* Right menu list */}
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            display={{ base: "flex", md: me ? "flex" : "none" }}
            variant="ghost"
            borderRadius="full"
            icon={me ? <Avatar size="xs" src={me.avatar || undefined} /> : <Box as={FiMenu} />}
          />

          <MenuList fontSize="md">
            {me ? (
              <>
                <NextLink href="/profile">
                  <MenuItem icon={<Box as={FiUser} boxSize="16px" />}>
                    {bt({ en: "Profile", nl: "Profiel" })}
                  </MenuItem>
                </NextLink>
                {me.role === Role.Admin && (
                  <NextLink href="/admin">
                    <MenuItem icon={<Box as={FiSettings} boxSize="16px" />}>
                      {" "}
                      {bt({ en: "Admin", nl: "Admin" })}
                    </MenuItem>
                  </NextLink>
                )}
                <MenuDivider />
                <MenuItem
                  closeOnSelect={false}
                  icon={<Box as={isDark ? FiSun : FiMoon} boxSize="16px" />}
                  onClick={toggleColorMode}
                >
                  {bt({ en: "Toggle theme", nl: "Kies thema" })}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => logout()} icon={<Box as={FiLogOut} boxSize="16px" />}>
                  {bt({ en: "Logout", nl: "Uitloggen" })}
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  closeOnSelect={false}
                  icon={<Box as={isDark ? FiSun : FiMoon} boxSize="16px" />}
                  onClick={toggleColorMode}
                >
                  {bt({ en: "Toggle theme", nl: "Kies thema" })}
                </MenuItem>
                <MenuDivider />
                <NextLink href="/login">
                  <MenuItem>{bt({ en: "Login", nl: "Login" })}</MenuItem>
                </NextLink>
              </>
            )}
          </MenuList>
        </Menu>
      </Limiter>
      <Modal
        {...createInvoiceProps}
        title={bt({ en: "Add invoice", nl: "Omzetfactuur toevoegen" })}
        size="2xl"
      >
        <CreateInvoiceForm onClose={createInvoiceProps.onClose} />
      </Modal>
      <Modal {...createExpenseProps} title={bt({ en: "Upload expense", nl: "Uitgave uploaden" })} size="2xl">
        <CreateExpenseForm onClose={createExpenseProps.onClose} />
      </Modal>
    </Box>
  )
}

interface HomeLinkProps extends LinkProps {
  href: string
}

function HomeLink({ href, ...props }: HomeLinkProps) {
  const { asPath } = useRouter()
  const isActive = asPath === href

  return (
    <Link
      as={NextLink}
      px={4}
      py={2}
      href={href}
      textDecor="none !important"
      _hover={{ color: isActive ? "pink.600" : "pink.500" }}
      color={isActive ? "pink.600" : "gray.500"}
      {...props}
    >
      {props.children}
    </Link>
  )
}

interface BreadcrumbLinksProps {
  entity: EntityOverviewFragment
  year?: string
  quarter?: string
  incomeDataId?: string
  reportId?: string
}

function BreadcrumbLinks({ entity, year, quarter, incomeDataId, reportId }: BreadcrumbLinksProps) {
  const bt = useBetterTranslation()
  const { data } = useGetReportQuery({ variables: { where: { id: { equals: reportId } } } })
  const report = data?.report
  return (
    <HStack spacing={1}>
      <Link href="/" as={NextLink} fontWeight="bold" fontSize="lg">
        {bt({ en: "Home", nl: "Home" })}
      </Link>

      <Text fontSize="1rem"> / </Text>

      <Link as={NextLink} href={`/entities/${entity.id}`} fontWeight="bold" fontSize="lg">
        {entity.name}
      </Link>

      {year && (
        <>
          <Text fontSize="1rem"> / </Text>

          <Link
            as={NextLink}
            href={quarter ? `/entities/${entity?.id}/${year}/${quarter}` : `/entities/${entity?.id}/${year}`}
          >
            <Text fontWeight="bold" fontSize="lg">
              {year}
              {quarter && ` ${quarter}`}
            </Text>
          </Link>

          {incomeDataId && (
            <>
              <Text fontSize="1rem"> / </Text>{" "}
              <Text fontWeight="bold" fontSize="lg">
                Inkomstenbelasting
              </Text>
            </>
          )}
          {report && (
            <>
              <Text fontSize="1rem"> / </Text>{" "}
              <Text fontWeight="bold" fontSize="lg">
                {REPORT_TYPES[report.type]}
              </Text>
            </>
          )}
        </>
      )}
    </HStack>
  )
}
