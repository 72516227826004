import * as React from "react"
import { FiMessageSquare, FiXCircle } from "react-icons/fi"
import { gql } from "@apollo/client"
import type { FlexProps } from "@chakra-ui/react"
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { useColorModeValue } from "@chakra-ui/react"
import {
  Avatar,
  Box,
  Center,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import type { UpdateItemFragment } from "lib/graphql"
import {
  GetNewUpdatesCountDocument,
  MeDocument,
  SortOrder,
  useGetNewUpdatesCountQuery,
  useGetUpdatesQuery,
  useUpdateUpdateMutation,
} from "lib/graphql"
import { useMe } from "lib/hooks/useMe"
import { useMutationHandler } from "lib/hooks/useMutationHandler"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { NoData } from "./NoData"

dayjs.extend(relativeTime)

const _ = gql`
  fragment UpdateItem on Update {
    id
    message
    isRead
    createdAt
    sender {
      id
      firstName
      avatar
    }
  }

  query GetUpdates($where: UpdateWhereInput, $orderBy: [UpdateOrderByWithRelationInput!]) {
    me {
      id
      updates(where: $where, orderBy: $orderBy) {
        items {
          ...UpdateItem
        }
      }
    }
  }

  query GetNewUpdatesCount($where: UpdateWhereInput) {
    me {
      id
      updates(where: $where) {
        count
      }
    }
  }

  mutation UpdateUpdate($id: String!, $data: UpdateUpdateInput!) {
    updateUpdate(id: $id, data: $data) {
      ...UpdateItem
    }
  }
`

export function UpdatesPopover() {
  const { me } = useMe()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const bt = useBetterTranslation()

  const { data: newCountData, refetch: countRefetch } = useGetNewUpdatesCountQuery({
    pollInterval: 30_000,
    variables: { where: { isRead: { equals: false } } },
  })
  const { data, refetch, loading } = useGetUpdatesQuery({
    pollInterval: 30_000,
    variables: {
      orderBy: { createdAt: SortOrder.Desc },
      where: { isRead: { equals: tabIndex === 0 ? false : true } },
    },
  })

  const handleRefetch = () => {
    refetch()
    countRefetch()
  }

  const newCount = newCountData?.me?.updates.count || 0
  const updates = data?.me?.updates.items

  const selectedColor = useColorModeValue("black", "white")

  if (!me) return null

  return (
    <Popover placement="top-end">
      <PopoverTrigger>
        <IconButton
          ml="auto"
          mr={2}
          aria-label="Open Updates"
          variant="ghost"
          borderRadius="full"
          icon={
            <Box pos="relative">
              <Box as={FiMessageSquare} size="22" p={0} />
              {newCount > 0 && (
                <Flex
                  pos="absolute"
                  top={-1}
                  right={-1}
                  bg="pink.500"
                  w="15px"
                  h="15px"
                  borderRadius="50%"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  fontSize="12px"
                >
                  {newCount}
                </Flex>
              )}
            </Box>
          }
        />
      </PopoverTrigger>
      <Portal>
        <Tabs onChange={(index) => setTabIndex(index)}>
          <PopoverContent w="600px" p={0}>
            <PopoverArrow />
            <PopoverHeader fontWeight="bold" fontSize="xl" pt={2} pb={0} pr={0}>
              <Flex justify="space-between" align="center">
                <Text pb={2}>Updates</Text>
                <TabList border="none">
                  <Tab
                    fontWeight="bold"
                    color="gray.500"
                    fontSize={{ base: "xs", sm: "sm" }}
                    paddingInlineStart={{ base: 1, sm: 2, md: 4 }}
                    paddingInlineEnd={{ base: 1, sm: 2, md: 4 }}
                    _selected={{
                      color: selectedColor,
                      borderColor: "pink.500",
                      borderBottomWidth: 2,
                    }}
                  >
                    <Flex align="center">
                      <Text>{bt({ en: "New", nl: "Nieuwe" })}</Text>
                      {newCount > 0 && (
                        <Flex
                          ml={2}
                          alignItems="center"
                          justifyContent="center"
                          bg="pink.500"
                          color="white"
                          fontSize="xs"
                          p={2}
                          borderRadius="50%"
                          w="15px"
                          h="15px"
                        >
                          {newCount}
                        </Flex>
                      )}
                    </Flex>
                  </Tab>
                  <Tab
                    fontWeight="bold"
                    color="gray.500"
                    fontSize={{ base: "xs", sm: "sm" }}
                    paddingInlineStart={{ base: 1, sm: 2, md: 4 }}
                    paddingInlineEnd={{ base: 1, sm: 2, md: 4 }}
                    _selected={{
                      color: selectedColor,
                      borderColor: "pink.500",
                      borderBottomWidth: 2,
                    }}
                  >
                    {bt({ en: "Archived", nl: "Gearchiveerd" })}
                  </Tab>
                </TabList>
              </Flex>
            </PopoverHeader>
            <PopoverBody p={0} maxH="300px" overflowY="scroll">
              <TabPanels>
                <TabPanel px={0} maxH="300px" overflowY="scroll">
                  <UpdatesList updates={updates} loading={loading} refetch={handleRefetch} />
                </TabPanel>
                <TabPanel px={0} maxH="300px" overflowY="scroll">
                  <UpdatesList updates={updates} loading={loading} refetch={handleRefetch} />
                </TabPanel>
              </TabPanels>
            </PopoverBody>
          </PopoverContent>
        </Tabs>
      </Portal>
    </Popover>
  )
}

interface ListProps {
  loading: boolean
  updates: UpdateItemFragment[] | undefined
  refetch: () => void
}

function UpdatesList({ loading, updates, refetch }: ListProps) {
  const bt = useBetterTranslation()
  return (
    <>
      {loading ? (
        <Center h="200px">
          <Spinner />
        </Center>
      ) : updates && updates.length > 0 ? (
        updates?.map((update, i) => (
          <UpdateItem
            key={update.id}
            updateItem={update}
            refetch={refetch}
            borderBottom={i !== updates.length - 1 ? "1px solid" : undefined}
          />
        ))
      ) : (
        <Center h="200px">
          <NoData>{bt({ en: "No updates", nl: "Geen updates" })}</NoData>
        </Center>
      )}
    </>
  )
}

interface ItemProps extends FlexProps {
  updateItem: UpdateItemFragment
  refetch: () => void
}

function UpdateItem({ updateItem, refetch, ...props }: ItemProps) {
  const [updateUpdate] = useUpdateUpdateMutation()
  const handler = useMutationHandler()
  const bt = useBetterTranslation()

  const handleRead = () => {
    return handler(
      () =>
        updateUpdate({
          variables: { id: updateItem.id, data: { isRead: true } },
          refetchQueries: [{ query: MeDocument }, { query: GetNewUpdatesCountDocument }],
        }),
      {
        onSuccess: (_) => {
          refetch()
        },
      },
    )
  }
  return (
    <Flex
      {...props}
      pos="relative"
      justifyContent="space-between"
      alignItems="flex-end"
      p={4}
      borderColor={useColorModeValue("gray.200", "gray.600")}
    >
      <Stack spacing={0} w="100%">
        <Flex>
          <Avatar src={updateItem.sender.avatar || ""} size="xs" mr={2} />
          <Stack pr={6} spacing={0}>
            <Text fontWeight="bold">
              {updateItem.sender.firstName}{" "}
              {bt({ en: "sent you a new update", nl: "stuurde je een nieuwe update" })}
            </Text>
            <Text opacity={0.8} fontSize="sm">
              {updateItem.message}
            </Text>
          </Stack>
        </Flex>
        <Text textAlign="right" fontSize="xs" ml="auto" opacity={0.8}>
          {dayjs(updateItem.createdAt).fromNow()}
        </Text>
      </Stack>
      {!updateItem.isRead && (
        <IconButton
          color="gray.400"
          pos="absolute"
          top={2}
          right={2}
          aria-label={`Mark as read`}
          variant="ghost"
          onClick={handleRead}
          size="xs"
          borderRadius="full"
          icon={<Box as={FiXCircle} boxSize="15px" p={0} />}
        />
      )}
    </Flex>
  )
}
