import { ReportType } from "lib/graphql"

export const REPORT_TYPES = {
  [ReportType.Sales]: "Omzetbelasting",
  [ReportType.Corporate]: "Vennootschapsbelasting",
  [ReportType.Personal]: "Inkomstenbelasting",
  [ReportType.Annual]: "Jaarrekening",
  [ReportType.IcpOpgaaf]: "ICP Opgaaf",
  [ReportType.Jaarsuppletie]: "Jaarsuppletie",
}
